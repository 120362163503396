import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.page.html',
  styleUrls: ['./preview.page.scss'],
})
export class PreviewPage implements OnInit {

  public pic:string="";
  constructor(private navParams: NavParams,private modalCtrl: ModalController) { }

  ngOnInit() {
    this.pic=this.navParams.get("pic")
  }

  dismiss(sent=false) {
    this.modalCtrl.dismiss({
    });
  }
}