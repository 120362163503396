import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy} from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { AuthenticateService } from './services/auth.service';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { provideMessaging,getMessaging } from '@angular/fire/messaging';

import { provideFirestore,getFirestore, enableIndexedDbPersistence, connectFirestoreEmulator } from '@angular/fire/firestore';
import { ComponentsModule } from '../app/components/components.module';
import { PopupMenuComponent }  from '../app/components/popup-menu/popup-menu.component';
/* import { CategoriesComponent } from '../app/components/categories/categories.component';
import { BusinessSendQuotePageModule } from '../app/pages/business-send-quote/business-send-quote.module';
import { RatingPageModule } from '@pages/rating/rating.module';
import { QuoteDetailsPageModule } from '../app/pages/quote-details/quote-details.module';
import { ContactFormPageModule } from '../app/pages/contact-form/contact-form.module';
import { ForgotpasswordPageModule } from '../app/pages/forgotpassword/forgotpassword.module';
import { CreditCardFormPageModule } from '../app/pages/credit-card-form/credit-card-form.module';
import { PayWithPaystackPageModule } from '@pages/pay-with-paystack/pay-with-paystack.module';
 */import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Camera } from '@ionic-native/camera/ngx';
import { File } from '@ionic-native/file/ngx';
import { Stripe } from '@ionic-native/stripe/ngx';
import { PreviewPageModule } from './pages/preview/preview.module';
import { TosPageModule } from '@pages/tos/tos.module';
import { Angular4PaystackModule } from 'angular4-paystack';
import { environment } from '../environments/environment';
import { getApp, initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { provideAuth,getAuth, connectAuthEmulator, initializeAuth, indexedDBLocalPersistence } from '@angular/fire/auth';
import { provideFunctions,getFunctions } from '@angular/fire/functions';
import { provideStorage,getStorage } from '@angular/fire/storage';
import { Capacitor } from '@capacitor/core';
import { connectStorageEmulator } from 'firebase/storage';

@NgModule({
    declarations: [AppComponent, PopupMenuComponent],
    imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule,
        HttpClientModule, PreviewPageModule, TosPageModule,
        ReactiveFormsModule, ComponentsModule,  
         FormsModule, 
        Angular4PaystackModule.forRoot('pk_test_5a689aa4d3f6762b353f1b4ea0a0b8b42e38b0d1'),
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideAuth(() => {
            if (Capacitor.isNativePlatform()) {
                return initializeAuth(getApp(), {
                    persistence: indexedDBLocalPersistence,
                });
            }
            else {
                const auth = getAuth();
                // connectAuthEmulator(auth, 'http://localhost:9099');
                return auth;
            }
        }),
        provideFirestore(() => {
            const firestore = getFirestore();
            enableIndexedDbPersistence(firestore);
            // connectFirestoreEmulator(firestore, 'localhost',8081)
            return firestore;
        }),
        provideFunctions(() => getFunctions()),
        provideMessaging(() => getMessaging()),
        provideStorage(() => {
            const storage = getStorage();
            // connectStorageEmulator(storage, 'localhost', 9199);
            return storage;
        })],
    providers: [Stripe,
        SplashScreen,
        AuthenticateService,
        ,
        Camera, File, HttpClient,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
