import { Injectable } from "@angular/core";import {
  Auth,
  createUserWithEmailAndPassword, sendPasswordResetEmail, signInAnonymously, signInWithEmailAndPassword, signOut,
  User, UserCredential,
  } from '@angular/fire/auth';
import { StorageService } from '@services/storage.service';
import { Observable, of } from 'rxjs';

@Injectable()
export class AuthenticateService {
 
  constructor(private storage: StorageService, private readonly auth: Auth)
  {}
 
  registerUser(value){
     return createUserWithEmailAndPassword(this.auth, value.email, value.password)
     .then(res => {return res.user;})
     .catch(err => { return err })
  }
 
  loginUser(value){
   return signInWithEmailAndPassword(this.auth, value.email, value.password)
     .then(res => {return res.user;})
     .catch(err => { return err; })
  }
 
  loginAsGuest(){
    return signInAnonymously(this.auth)
      .then(res => {return res.user;})
      .catch(err => { return err; })
   }
  
  async logoutUser(){
    
    // preserve the token when you log out
    const token = await this.storage.get('deviceToken');
    await this.storage.clear()
    await this.storage.set('deviceToken', token);
    
    console.log("storage cleared. logging out");
    signOut(this.auth)
      .then(() => { return (true);})
      .catch((error) => { return(error); });
  }
 
  getUser(): User {
    return this.auth.currentUser; 
  }
  getUser$(): Observable<User> 
  { return of(this.getUser());
   }

  forgotpassword(email:string){
   
    return sendPasswordResetEmail(this.auth, email);

  }
}