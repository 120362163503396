import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {AuthenticationGuard} from 'src/app/authentication/authentication.guard'

const routes: Routes = [
  /*{ path: '', redirectTo: 'home', pathMatch: 'full' },*/
  //{ path: '', loadChildren: './pages/tabs/tabs.module#TabsPageModule',canActivate: [AuthGuardService]},
  { path: '',
    // loadChildren: () => import('./authentication/authentication.module').then( m => m.AuthenticationPageModule)
    loadChildren: () => import('./pages/start/start.module').then( m => m.StartPageModule)
  },
  {
    path: 'start',
    loadChildren: () => import('./pages/start/start.module').then( m => m.StartPageModule)
  },
  {
    path: 'home', loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'phone-login',
    loadChildren: () => import('./authentication/authentication.module').then( m => m.AuthenticationPageModule)
  },
  {
    path: 'phone-register',
    loadChildren: () => import('./authentication/authentication.module').then( m => m.AuthenticationPageModule)
  },
  {
    path: 'email-login',
    loadChildren: () => import('./pages/email-login/email-login.module').then( m => m.EmailLoginPageModule)
  },
  {
    path: 'email-register',
    loadChildren: () => import('./pages/email-register/email-register.module').then( m => m.EmailRegisterPageModule)
  },
  {
    path: 'request-form',
    loadChildren: () => import('./pages/request-form/request-form.module').then( m => m.RequestFormPageModule)
  },
  {
    path: 'request-details/:id',
    loadChildren: () => import('./pages/request-details/request-details.module').then( m => m.RequestDetailsPageModule)
  },
  {
    path: 'completed-request-details/:id',
    loadChildren: () => import('./pages/completed-request-details/completed-request-details.module').then( m => m.CompletedRequestDetailsPageModule)
  },
  {
    path: 'tabs',
    loadChildren: () => import('./pages/tabs/tabs.module').then( m => m.TabsPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'business-account-form',
    loadChildren: () => import('./pages/business-account-form/business-account-form.module').then( m => m.BusinessAccountFormPageModule)
  },
  {
    path: 'business-account',
    loadChildren: () => import('./pages/business-account/business-account.module').then( m => m.BusinessAccountPageModule)
  },
  {
    path: 'business-send-quote',
    loadChildren: () => import('./pages/business-send-quote/business-send-quote.module').then( m => m.BusinessSendQuotePageModule)
  },
  {
    path: 'quote-details',
    loadChildren: () => import('./pages/quote-details/quote-details.module').then( m => m.QuoteDetailsPageModule)
  },
  {
    path: 'business-request-details',
    loadChildren: () => import('./pages/business-request-details/business-request-details.module')
      .then( m => m.BusinessRequestDetailsPageModule)
  },
  {
    path: 'messages',
    loadChildren: () => import('./pages/messages/messages.module').then( m => m.MessagesPageModule)
  },
  {
    path: 'rating',
    loadChildren: () => import('./pages/rating/rating.module').then( m => m.RatingPageModule)
  },
  {
    path: 'messages-list',
    loadChildren: () => import('./pages/messages-list/messages-list.module').then( m => m.MessagesListPageModule)
  },
/*   {
    path: 'active-request',
    loadChildren: () => import('./pages/active-request/active-request.module').then( m => m.ActiveRequestPageModule)
  }, */
  {
    path: 'user-profile',
    loadChildren: () => import('./pages/user-profile/user-profile.module').then( m => m.UserProfilePageModule)
  },
  {
    path: 'load-credits',
    loadChildren: () => import('./pages/load-credits/load-credits.module').then( m => m.LoadCreditsPageModule)
  },
  {
    path: 'credit-card-form',
    loadChildren: () => import('./pages/credit-card-form/credit-card-form.module').then( m => m.CreditCardFormPageModule)
  },
  {
    path: 'preview',
    loadChildren: () => import('./pages/preview/preview.module').then( m => m.PreviewPageModule)
  },
  {
    path: 'completed-requests',
    loadChildren: () => import('./pages/completed-requests/completed-requests.module').then( m => m.CompletedRequestsPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'contact-form',
    loadChildren: () => import('./pages/contact-form/contact-form.module').then( m => m.ContactFormPageModule)
  },
  {
    path: 'tos',
    loadChildren: () => import('./pages/tos/tos.module').then( m => m.TosPageModule)
  },
  {
    path: 'pay-with-paystack',
    loadChildren: () => import('./pages/pay-with-paystack/pay-with-paystack.module').then( m => m.PayWithPaystackPageModule)
  },
  {
    path: 'credit-history',
    loadChildren: () => import('./pages/credit-history/credit-history.module').then( m => m.CreditHistoryPageModule)
  },
  {
    path: 'forgotpassword',
    loadChildren: () => import('./authentication/authentication.module').then( m => m.AuthenticationPageModule)
  },
  {
    path: 'authentication',
    loadChildren: () => import('./authentication/authentication.module').then( m => m.AuthenticationPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationPageModule),
  },
  {
    path: 'signup',
    loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationPageModule),
  },
  {
    path: 'reset',
    loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationPageModule),
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
