import {NgModule} from '@angular/core';
import { TopRightMenuComponent } from '../../app/components/top-right-menu/top-right-menu.component';
import {IonicModule} from '@ionic/angular';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        CommonModule,
        IonicModule
    ],
    declarations:[TopRightMenuComponent],
    exports: [TopRightMenuComponent]
})

export class ComponentsModule{}