import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Router } from '@angular/router';
import { PopupMenuComponent } from '../popup-menu/popup-menu.component';

@Component({
  selector: 'app-top-right-menu',
  templateUrl: './top-right-menu.component.html',
  styleUrls: ['./top-right-menu.component.scss'],
})
export class TopRightMenuComponent implements OnInit {

  currentPopover:any = null;
  
  constructor(public popoverController: PopoverController, private router: Router) { }

  ngOnInit() {}

  async popupMenu(ev: any)
  {
    const popover = await this.popoverController.create({
      animated: true,
      component: PopupMenuComponent,
      event: ev,
      translucent: true,
      componentProps: {homeref:this}
    });
    this.currentPopover=popover;
    return await popover.present();
  } 

  goTo(page)
  {
    if (this.currentPopover) {
      this.currentPopover.dismiss().then(() => { this.currentPopover = null; });
    }
    this.router.navigate([page]).then((n)=>{
      console.log("Going to " + page);
    }).catch((e)=>{console.warn("Error navigating: ",e)})
  }

  dismissPopup()
  {
    if (this.currentPopover) {
      this.currentPopover.dismiss().then(() => { this.currentPopover = null; });
    }
  }
  logOut()
  {
    this.goTo('login');
  }
}
