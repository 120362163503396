import { Injectable } from '@angular/core';
import { Storage } from '@capacitor/storage';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  // Create and expose methods that users of this service can
  // call, for example:
  public async set(_key: string, value: any) {
    await Storage.set({
      key: _key,
      value: JSON.stringify(value),
    });
  }

  public async get(_key: string)
  { const item = await Storage.get({ key: _key });
    return (item.value !== "") ? JSON.parse(item.value) : null;
  }

  public async remove(_key: string)
  {
    await Storage.remove({key: _key});
  }

  public async clear()
  {
    await Storage.clear();
  }
}