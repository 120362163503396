import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticateService } from '../../services/auth.service';

@Component({
  selector: 'app-popup-menu',
  templateUrl: './popup-menu.component.html',
  styleUrls: ['./popup-menu.component.scss'],
})
export class PopupMenuComponent implements OnInit {
  constructor(private authService: AuthenticateService,private navParams: NavParams,private router: Router,
    private popoverController: PopoverController, private route: ActivatedRoute) { }

  ngOnInit() { }

  comingsoon()
  {
    alert('Coming soon');
  }

  goTo(page)
  { this.dismissMenu().then(()=>{
      this.router.navigate([page], {relativeTo: this.route}).then(()=>{
      console.log('Going to ' + page);
      }).catch((e)=>{console.warn('Error navigating: ',e)})
    });
  }

  async dismissMenu() {
   await this.popoverController.dismiss();
  }

  logOut(){
    this.authService.logoutUser()
    .then(() => {
      this.goTo('start');
    })
    .catch(error => {
      console.log(error);
    })
  }
}
