import { Component } from '@angular/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { StorageService } from '@services/storage.service';
import { FCM } from '@capacitor-community/fcm';
import { Device } from '@capacitor/device';
import { Dialog } from '@capacitor/dialog';

import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  pushNotificationToken: any; // I save the token to database because i need it in my backend code.

  constructor(
    private storage: StorageService) {
      Device.getInfo().then(res => {
        if (res.platform !== 'web') {
          this.getToken();
        }
      });
  }

  getToken() {
    PushNotifications.requestPermissions().then(async (permission) => {
        if (permission.receive === 'granted') {
            // Register with Apple / Google to receive push via APNS/FCM
            if (Capacitor.getPlatform() === 'ios') {
                await PushNotifications.register();
                PushNotifications.addListener('registration', (token: Token) => {
                    FCM.getToken().then(async (result) => {
                        console.log('ios token', result.token); // This is token for IOS
                        await this.storage.set('deviceToken', result.token);
                    }).catch((err) => console.log('FCM.getToken() Error', err));
                })
            } else if (Capacitor.getPlatform() === 'android') {
              try {
                await PushNotifications.register()
                PushNotifications.addListener('registration', async ({ value }) => {
                  console.log('android token', value);
                  await this.storage.set('deviceToken', value);// this is token for Android use this token
                });
              } catch (error) {
                alert({error});
              }
            }
            this.registerPush();
        } else {
            // No permission for push granted
            alert('No Permission for Notifications!')
        }
    });
}
  private registerPush() {
    PushNotifications.addListener(
      'pushNotificationReceived',
      async (notification: PushNotificationSchema) => {
        console.log('notificationbody', notification.body);
        await Dialog.alert({
          title: notification.title,
          message: notification.body,
        });
      }
    ).catch((e)=>{console.warn('err',e)});



    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      async (notification: ActionPerformed) => {
        console.log('Action performed: ' + JSON.stringify(notification));
      }
    );
  }

  resetBadgeCount() {
    Device.getInfo().then(res => {
      if (res.platform !== 'web') {
        PushNotifications.removeAllDeliveredNotifications();
      }
    });
  }
}
