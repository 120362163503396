// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    projectId: 'service-finder-a7243',
    appId: '1:958385747610:web:2a9e535792f4352762d123',
    databaseURL: 'https://service-finder-a7243.firebaseio.com',
    storageBucket: 'service-finder-a7243.appspot.com',
    locationId: 'us-central',
    apiKey: 'AIzaSyAtCJbonqNrK-nE0UbInWBZjC7ZT2Hp8nQ',
    authDomain: 'service-finder-a7243.firebaseapp.com',
    messagingSenderId: '958385747610',
  },
  geocoding: {
    api_key:'AIzaSyAtCJbonqNrK-nE0UbInWBZjC7ZT2Hp8nQ',
    url: 'https://maps.googleapis.com/maps/api/geocode/json?'
  },
  stripe:
  {
    publishableKey:'pk_test_KfhgGlYzdbnyulqzG4xAJSWL',
    secretKey:'sk_test_1IsCDI8YgfaSM5omaccRVNGt',
    chargeURL:'https://servicefinderapp.com/api/stripefn.php?action=charge&dev=1', //'http://localhost:8888/servicefinderapp.com/api/stripefn.php?action=deletecreditcard&dev=1'
    newCustomerURL:'https://servicefinderapp.com/api/stripefn.php?action=createcustomer&dev=1',
    newCardURL:'https://servicefinderapp.com/api/stripefn.php?action=addcreditcard&dev=1',
    updateCardURL:'https://servicefinderapp.com/api/stripefn.php?action=editcreditcard&dev=1',
    deleteCardURL:'https://servicefinderapp.com/api/stripefn.php?action=deletecreditcard&dev=1'
  },
  paystack:
  {
    publishableKey:'pk_test_5a689aa4d3f6762b353f1b4ea0a0b8b42e38b0d1'
  },
  functionapi:{
    contactUrl : 'https://us-central1-service-finder-a7243.cloudfunctions.net/sendMail',
    //contactUrl : 'http://localhost:5000/service-finder-a7243/us-central1/sendMail'
  },
  sfURL:'https://servicefinderapp.com/' // http://localhost:8888/servicefinderapp.com/

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
