import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-tos',
  templateUrl: './tos.page.html',
  styleUrls: ['./tos.page.scss'],
})
export class TosPage implements OnInit {
  lang: string = "en";

  constructor(public modalCtrl: ModalController, private navParams: NavParams) { }

  ngOnInit() {

    if (this.navParams.get('lang')) this.lang = this.navParams.get('lang');
  }

  dismiss()
  {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }
}